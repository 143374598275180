<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M11 20a.5.5 0 0 1-.637.48l-9.5-2.714a.5.5 0 0 1-.363-.48V1a.5.5 0 0 1 .637-.48l9.5 2.714a.5.5 0 0 1 .363.48V20zM10 4.091L1.5 1.663v15.246l8.5 2.428V4.091z"
    />
    <path
      d="M20.5 17.286a.5.5 0 0 1-.363.48l-9.5 2.715A.5.5 0 0 1 10 20V3.714a.5.5 0 0 1 .363-.48l9.5-2.715A.5.5 0 0 1 20.5 1v16.286zm-1-.377V1.663L11 4.09v15.246l8.5-2.428z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
